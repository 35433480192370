<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">ตั้งค่าการจัดส่ง</h1>
        </b-col>
        <b-col xl="8" class="text-right">
          <div class="d-flex justify-content-end">
            <b-button variant="main" @click="addNewConditon()"
              >เพิ่มเงื่อนไข</b-button
            >
          </div>
        </b-col>
      </CRow>
      <div class="mt-3 bg-white px-0 pb-3">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
              class="table-list"
            >
              <template v-slot:cell(start_weight)="{ item, index }">
                <InputText
                  textFloat=""
                  placeholder="น้ำหนักเริ่ม"
                  type="number"
                  @onKeyup="handleMaxPrice(item, $event)"
                  name="start_weight"
                  v-model="item.value"
                  :isValidate="$v.items.$each.$iter[index].value.$error"
                  :v="$v.items.$each.$iter[index].value"
                  isRequired
                />
              </template>
              <template v-slot:cell(price)="{ item, index }">
                <InputText
                  textFloat=""
                  placeholder="ราคา"
                  type="number"
                  name="price"
                  v-model="item.price"
                  :isValidate="$v.items.$each.$iter[index].price.$error"
                  :v="$v.items.$each.$iter[index].price"
                  isRequired
                />
              </template>
              <template v-slot:cell(isCentral)="{ item }">
                <b-form-checkbox
                  size="lg"
                  :id="'central-' + item.id"
                  class=""
                  :value="1"
                  :unchecked-value="0"
                  v-model="item.isCentral"
                >
                </b-form-checkbox>
              </template>

              <template v-slot:cell(id)="{ item }">
                <b-button
                  variant="icon"
                  class="btn-shipping-set"
                  @click="deleteConditon(item)"
                >
                  <font-awesome-icon icon="trash-alt" class="icon d-block" />
                </b-button>
              </template>

              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>
            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mt-5 px-3">
          <b-col md="6"></b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              @click="submit()"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึก
            </button>
          </b-col>
        </b-row>
      </div>
    </div>
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import { required, minValue } from "vuelidate/lib/validators";
export default {
  name: "Shipping",
  components: {
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
    InputText,
  },
  data() {
    return {
      statusList: [],
      modalMessage: "",
      id: "",
      requestDeleteBanner: {
        id: null,
      },
      fields: [
        {
          key: "start_weight",
          label: "น้ำหนักเริ่ม",
          class: "w-100px",
        },

        {
          key: "price",
          label: "ราคา",
          class: "w-100px",
        },
        {
          key: "isCentral",
          label: "ปริมณฑล",
          class: "w-100px",
        },
        {
          key: "id",
          label: "",
        },
      ],
      items: [{ start_weight: "", weight: "", price: "", id: 0 }],
      isBusy: false,
      rows: 0,
      filter: {
        PageNo: 1,
        PerPage: 10,
        Type: 1,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      totalRowMessage: "",
      isDisable: false,
      deleteItems: {},
      addItems: [],
    };
  },
  validations: {
    items: {
      $each: {
        value: { required, minValue: minValue(1) },
        price: { required, minValue: minValue(1) },
      },
    },
  },
  created: async function () {
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    getList: async function (filter = this.filter) {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/CriteriaShipping/List`,
        null,
        this.$headers,
        filter
      );

      if (resData.result == 1) {
        if (resData.detail.dataList.length != this.filter.PerPage) {
          this.items = [...resData.detail.dataList, ...this.addItems];
        } else {
          this.items = resData.detail.dataList;
        }
        this.rows = resData.detail.count + this.addItems.length;

        this.isBusy = false;
        this.$isLoading = true;
      }
    },

    pagination(Page) {
      this.filter.PageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
    async deleteConditon(items) {
      this.$refs.ModalAlertConfirm.show();
      this.modalMessage = "";
      this.deleteItems = items;
    },
    btnDelete: async function () {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/CriteriaShipping/Delete`,
        null,
        this.$headers,
        this.deleteItems
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();
        // this.filter.PageNo = 1;
        this.rows -= 1;
        if (
          this.filter.PageNo >
          Math.ceil((this.rows + this.addItems.length) / this.filter.PerPage)
        )
          this.filter.PageNo -= 1;
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        await this.getList();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    handleMaxPrice(items, e) {
      // const index = this.items.findIndex((el) => el.id == items.id);
      // this.$nextTick(() => {
      //   if (Number(this.items[index].start_weight) > items.weight) {
      //     return (this.items[index].start_weight = items.weight);
      //   }
      // });
    },
    handleMinPrice(items, e) {
      // const index = this.items.findIndex((el) => el.id == items.id);
      // this.$nextTick(() => {
      //   if (Number(this.items[index].weight) < items.start_weight) {
      //     return (this.items[index].weight = items.start_weight);
      //   }
      // });
    },
    async addNewConditon() {
      this.$v.items.$reset();
      this.addItems.push({
        id: 0,
        type: 1,
        isCentral: 0,
        price: 0,
        value: 0,
      });

      let filter = { ...this.filter };
      filter.PageNo = Math.ceil(
        (this.rows + this.addItems.length) / this.filter.PerPage
      );

      await this.getList(filter);

      this.$nextTick(() => (this.filter = filter));
    },
    async updateCondition() {
      let payload = this.items.filter((el) => el.id != 0);
      if (payload.length == 0) return await this.getList();
      this.isBusy = true;

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/CriteriaShipping/Update`,
        null,
        this.$headers,
        payload
      );

      if (resData.result == 1) {
        await this.getList();

        this.isBusy = false;
        this.$isLoading = true;
      } else {
        this.isBusy = false;
        this.$isLoading = true;
        this.modalMessage = resData.detail.join(",");
        this.$refs.modalAlertError.show();
      }
    },
    async addCondition() {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/CriteriaShipping/Insert`,
        null,
        this.$headers,
        this.addItems
      );

      if (resData.result == 1) {
        this.addItems = [];
      } else {
        this.isBusy = false;
        this.$isLoading = true;
        this.modalMessage = resData.detail.join(",");
        this.$refs.modalAlertError.show();
      }
    },
    async submit() {
      this.$v.items.$touch();
      if (this.$v.items.$error) return;
      if (this.addItems.length > 0) await this.addCondition();
      this.updateCondition();
    },
  },
};
</script>

<style scoped>
.image {
  width: 75px;
  padding-top: 75px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
}
</style>
